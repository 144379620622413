import { zIndices } from '@loveholidays/design-system';
import React from 'react';

/**
 * On SSR fetch the header data and inline it
 * On Client accept what was rendered on SSR without changing it
 */
const Header: React.FC = () => {
  const header =
    typeof window === 'undefined'
      ? // eslint-disable-next-line global-require
        require('@Server/utils/holidaypirates/useHolidayPiratesData').useHolidayPiratesData(
          'header',
        )
      : '';

  return (
    <section
      // This fixes the HPR header being z-index: 999 and uses our zIndices instead
      sx={{
        '> #hp-header#hp-header#hp-header > div': {
          zIndex: zIndices.stickyContent,
        },
      }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: header,
      }}
    />
  );
};

export default Header;
